.ant-layout-sider-children{
    animation: layout_sider 1s forwards; 
}
.sidebar-tab-icon{
    width: 20px;
    height: 20px;
}

.sidebar-big{
    flex: 0 0 260px !important; 
    max-width: 265px !important;
    min-width: 265px !important;
    width: 265px !important;
    height: 100dvh !important;
    overflow-x: hidden;
}
  
.sidebar-small{
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
}

.sidebar-logo-big {
    /* padding: 20px;
    height: 90px; */
    position: sticky;
    z-index: 99;
    top: 0  !important;
    background-color: transparent;
    /* margin-bottom: 15px; */
    padding: 0px 20px 20px 20px;
    margin-top: -5px;
}
.sidebar-logo-small {
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 70px;
    transition: all 0.3s ease;
}

.logo {
    width: 60% !important;
    animation: logo 2s forwards !important; 
}


.small-logo{
    width: 60px;
    margin-left: -20px;
}
.dote {
    /* margin-top: 8px; */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(132deg) brightness(103%) contrast(103%);
}
.sidebar-big .ant-btn,
.sidebar-big .ant-btn:hover,
.sidebar-big .ant-btn:focus{
    background-color: black;
    border-color: gray;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 93%;
}

.sidebar-big .ant-btn .anticon {
    color: gray;
}
.profile-button {
    background-color: transparent;
}
.ant-popover .ant-popover-content .ant-popover-arrow .ant-popover-arrow-content::before{
    background: #30425cb8;
}
.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content{
    padding: 2px 12px 5px 12px;
    color: white;
    background: #30425cb8;
    border-radius: 6px
}
.ant-popover .ant-popover-content .ant-popover-inner{
    border-radius: 8px 
}
.pop-hover-content {
    width: 100%;
    cursor: pointer;
}
.ant-popover-placement-left, .ant-popover-placement-leftBottom, .ant-popover-placement-leftTop {
    margin-top: -3px;
}
.pop-hover-content .pop-profile {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    padding: 16px;
}

.pop-hover-content p {
    font-family: "Inter" !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.pop-hover-content .pop-logout {
    padding: 16px;
}

.pop-hover-content hr {
    border: 1px solid #455770;
}
.menu-header{
    position: fixed;
    width: 100%;
    z-index: 101;
}
.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-popover-message{
    color: white;
}
.sidebar-Footer-small .profile-details .profile-button {
    display: none;
}
.sidebar-small .ant-btn,
.sidebar-small .ant-btn:hover,
.sidebar-small .ant-btn:focus{
    background-color: black;
    border-color: gray;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 73%;
}

.sidebar-small .ant-btn .anticon {
    color: gray;
}


.ant-menu-item:hover {
    background-color: #526D82 !important;
}

.ant-menu-dark .ant-menu-item-selected:not(:hover) {
    background-color:#526D82 !important;
}

.sidebar-small  .ant-menu-dark .ant-menu-submenu-selected:not(:hover) {
    background-color: #0c2349 !important;
    border-radius: 5px;
}

.sidebar-big .ant-menu-dark .ant-menu-submenu-selected:not(:hover) {
    border-radius: 5px;
}

.sidebar-footer-content{
    display: flex;
    align-items: center;
    height: 23px;
}

.sidebar-footer-details-small{
    display: none;
}


.sidebar-footer-details-big{
    display: block;
    align-items: center;
}

.sidebar-Footer-small{
    position: fixed;
    width: 60px;
    bottom: 0;
    z-index: 1;
    height: 60px;
    color: #fff;
     padding-left: 8px;
     padding-top: 8px;
    /* background: #002140; */
    transition: all 0.5s ease;
    cursor: pointer;
}

.sidebar-Footer-big{
    position: fixed;
    width: 270px  !important;
    bottom: 0px !important;
    z-index: 99;
    height: 70px;
    color: #fff;
    padding-left: 10px;
    padding-top: 15px;
    background: #002140;
    transition: all 0.5s ease;
    cursor: pointer;
    animation: sidebar-Footer 2s forwards !important; 
}


.sidebar-Footer-small .profile-details p {
    display: none;
}

.sidebar-Footer-big .profile-details p {
    font-size: 14px;
    margin-bottom: 0px;
    font-family: 500;
    color: #7C7C7C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    color: white;
    margin-bottom: 5px;
    margin-top: -2px;
    margin-left: 0px;
}

.sidebar-Footer-big .profile-details .prof-img{
    width: 45px !important;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50% !important;
}

.sidebar-Footer-small .profile-details h3 {
     display: none;
}

.sidebar-Footer-big .profile-details  h3 {
    font-size: 13px;
    margin-bottom: 0px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: -20px;
}

.sidebar-Footer-small .profile-details .prof-img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50% !important;
}
/* .sidebar-logo-big a p{
    color: #e8f4f9;
    font-size: large;
    font-weight: 600;
    margin: 0;
} */
.hide{
    display: none;
}

@keyframes layout_sider {
    from {
        transform: translatex(-100%);
        opacity: 0;
    }
    to {
        transform: translatex(0);
        opacity: 1;
    }
  }

  @keyframes sidebar-Footer {
    from {
        transform: translatex(-100%);
        opacity: 0;
    }
    to {
        transform: translatex(0);
        opacity: 1;
    }
  }


  @keyframes logo {
    from {
        transform: translatex(-100%);
        opacity: 0;
    }
    to {
        transform: translatex(0);
        opacity: 1;
    }
  }
  .menucloseicon{
    width: 20px;
    filter: invert(92%) sepia(16%) saturate(5188%) hue-rotate(170deg) brightness(101%) contrast(84%);
  }
  .ant-ribbon.ant-ribbon-placement-end {
    /* right: -35px; */
    background: transparent;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
    z-index: 5;
  }
  /* .ant-ribbon-corner:after {
    display: none;
  } */
  .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
    display: none;
  }

  .animated-menu {
    opacity: 0; /* Initially hide the cards */
    animation: slideIn 3s ease forwards; /* Animation duration and easing */
}

@keyframes slideIn {
    from {
        opacity: 0; /* Start with opacity 0 */
        transform: translateX(-30px); /* Start position off-screen */
    }
    to {
        opacity: 1; /* Show the cards */
        transform: translateX(0); /* Move to original position */
    }
}

.animated-sub-menu {
    opacity: 0; /* Initially hide the cards */
    animation: slideIn 1s ease forwards; /* Animation duration and easing */
}

@keyframes slideIn {
    from {
        opacity: 0; /* Start with opacity 0 */
        transform: translateX(-30px); /* Start position off-screen */
    }
    to {
        opacity: 1; /* Show the cards */
        transform: translateX(0); /* Move to original position */
    }
}
.login-text {
    position: relative;
    font-size: 2.0rem;
    color: #82caeb;
    top: 3px;
    letter-spacing: 0.4px;
    font-weight: 600;
    padding-left: 10px;
    margin: 0;
    font-family: 'biome';
    right: 24px;
  
  }
  
  .login-text1 {
    position: relative;
    bottom: 18px;
    font-weight: bold;
    font-size: 26px;
    padding-left: 2px;
    color: #bef2c7;
    margin: 0;
    font-weight: 400;
    font-family: 'biome';
    right: 26px;
  }
  .slider{
    height: 100px;
}
.login_logo {
    position: relative;
    top: 50px;
    transform: rotate(-90deg);
    width: 47px;
    right: 92px;
}
.header_height{
    height: calc(100vh - 204px);
}
