@media screen  and (max-width: 600px){
    .dashboard-main{
        padding: 30px 20px 20px 20px !important;
    }

    .tracking-dashboard-main{
        padding: 30px 20px 20px 20px !important;
    }

    .trend-main {
        padding: 30px 20px 20px 20px !important;
    }

    .record-main{
        padding: 30px 20px 20px 20px !important;
    }

    .tracking-dashboard-main .table-header .table-header-btns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    .table-pagination h5{
        font-size: 13px;
        color: white;
        margin-top: 5px;
      }
}

