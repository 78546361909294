.delete-btn,
.delete-btn:hover,
.delete-btn:focus{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    background-color: #DF4343;
    border: none;
    padding: 0px 20px;
    font-family: DM Sans !important;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: white;
    border-radius: 5px;
}

.add-btn,
.add-btn:focus{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #27374d;
  border: none;
  padding: 0px 15px;
  font-family: DM Sans !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 5px;
  /* border: 1px solid #27374d; */
}
.add-btn:hover{
  background-color: #8797ad;
  color: white;
  /* border: 1px solid #161f2b; */
}
.dept-field {
  display: flex;
  justify-content: center; 
  align-self: center;
  margin-top: 20px;
}

.add-dept-btn,
.add-dept-btn:hover,
.add-dept-btn:focus {
  background-color: #0199DC;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  border: none;
}

.footer-button {
    padding-top: 20px;
    text-align: center;
}

.save-btn,
.save-btn:focus{
  background-color: rgba(0, 0, 255, 0.658);
  border: none;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 6px;
  width: 100px;
  height: 37px;
}
.save-btn-styl,
.save-btn-styl:focus{
  background-color: rgba(0, 0, 255, 0.658);
  border: none;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 6px;
  width: 100px;
}

.save-btn:hover{
  background-color: blue
}

.green-btn {
    background-color: #509b4f;
    border: none;
    color: #fff;
    min-width: 70px;
    font-size: 11px;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px;
}

.red-btn {
    background-color: #d44040;
    border: none;
    min-width: 70px;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px;
}

.file-upload{
  border-radius: 6px;
  height: 40px;
}

.footer-buttons {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cancel-btn,
.cancel-btn:focus {
  background-color: gray;
  border: none;
  color: #fff;
  font-family: DM Sans !important;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
.cancel-btn:hover{
  background-color: #686161;
  border: none;
  color: #fff;
  font-family: DM Sans !important;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
.cancel-btn-styl:hover{
  background-color: #888b96;
  border: none;
  color: #fff;
  font-family: DM Sans !important;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.cancel-btn-styl,
.cancel-btn-styl:focus {
  background-color: gray;
  border: none;
  color: #fff;
  font-family: DM Sans !important;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}

.del-btn,
.del-btn:hover,
.del-btn:focus {
  background-color: #DF4343;
  border: none;
  padding: 0px 20px;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  width: 100px;
}

.taskUpdate-btn,
.taskUpdate-btn:hover,
.taskUpdate-btn:focus{
  background-color: #0199DC;
  border: none;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white !important;
  border-radius: 6px;
}

.submit-btn,
.submit-btn:hover,
.submit-btn:focus{
  background-color: #0199DC;
  border: none;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white !important;
  border-radius: 6px;
}

.inspected-btn,
.inspected-btn:hover,
.inspected-btn:focus{
  background-color: #3F4354;
  border: none;
  font-family: DM Sans !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: white !important;
  border-radius: 6px;
  height: 34px;
}

.quid-btn,
.quid-btn:hover,
.quid-btn:focus{
  background-color: #6FBD1624;
  color: #6FBD16;
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  min-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.quid-btn-active,
.quid-btn-active:hover,
.quid-btn-active:focus{
  background-color: #6FBD16;
  color: #FFFFFF;
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  min-width: 70px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dep-btn,
.dep-btn:hover,
.dep-btn:focus{
  background-color: #0199DC33;
  color: #0199DC;
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.dep-btn-active,
.dep-btn-active:hover,
.dep-btn-active:focus{
  background-color: blue;
  color: white;
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.type-btn,
.type-btn:hover,
.type-btn:focus{
  background-color: rgba(216, 216, 2, 0.122);
  color: rgb(201, 201, 9);
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}


.type-btn-active,
.type-btn-active:hover,
.type-btn-active:focus{
  background-color: rgba(255, 255, 0, 0.923);
  color: black;
  border: none;
  height: 25px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.upload-btn,
.upload-btn:hover,
.upload-btn:focus{
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: #1C1C1C;
  border: none;
  height: 35px;
  font-family: DM Sans !important;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFFA6;
  border-radius: 5px;
}

.download-btn,
.download-btn:hover,
.download-btn:focus{
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: #1C1C1C;
  border: none;
  height: 36px;
  font-family: DM Sans !important;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFFA6;
  margin-top: 5px;
  border-radius: 5px;
}

.download-btn-two,
.download-btn-two:hover,
.download-btn-two:focus{
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: #1C1C1C;
  border: none;
  height: 36px;
  font-family: DM Sans !important;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFFA6;
  margin-top: 30px;
  border-radius: 5px;
}

.table-add-btn{
  background-color: #0199DC;
  padding-top: 1px;
  border: none;
  color: #fff;
  min-width: 50px;
  font-size: 11px;
  border-radius: 4px;
  cursor: pointer;
}

.report-red-btn{
  background-color: red;
  padding-top: 1px;
  border: none;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
  padding: 0px 5px;
  margin-right: 5px;
}

.blue-btn{
  background-color: blue;
  padding-top: 1px;
  border: none;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
  padding: 0px 5px;
  margin-right: 5px;
}

.yellow-btn{
  background-color: yellow;
  padding-top: 1px;
  border: none;
  color: black;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.gray-btn{
  background-color: gray;
  padding-top: 1px;
  border: none;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
  padding: 0px 5px;
  white-space: nowrap;
  margin-right: 5px;
}

.orange-btn{
  background-color: orange;
  padding-top: 1px;
  border: none;
  color: black;
  font-size: 10px;
  border-radius: 4px;
  padding: 0px 5px;
  margin-right: 5px;
}


.info-btn{
  background-color: white;
  border: none;
  color: black;
  font-size: 9px;
  border-radius: 4px;
  padding: 0px 5px;
  cursor: pointer;
  margin-top: 3px;
  padding-top: 1px;
}

.moreButton,
.moreButton:hover,
.moreButton:focus{
      background-color: transparent;
      border: 1px solid #0199DC;
      color: #0199DC;
      padding: 0px 15px;
      font-family: DM Sans !important;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      border-radius: 5px;
}
    
 .sub-btn,
 .sub-btn:hover,
 .sub-btn:focus {
    background-color: #EB5757;
    color: white;
    width: 40px;
    height: 34px;
    border-radius: 4px;
    margin-top: 31px;
    border: none;
}

 .plus-btn,
 .plus-btn:hover,
 .plus-btn:focus {
    background-color: #3157F6;
    color: white;
    width: 40px;
    height: 34px;
    border-radius: 4px;
    margin-top: 31px;
    border: none;
}

.preview_btn,
.preview_btn:hover,
.preview_btn:focus{
  background-color: #3157F6;
  color: white;
  height: 34px;
  border-radius: 4px;
  border: none;
}


.feature-csv-btn,
.feature-csv-btn:hover,
.feature-csv-btn:focus{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #0199DC;
  border: none;
  height: 25px;
  padding: 0px 15px;
  font-family: DM Sans !important;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 5px;
}
.ant-btn-primary ,
.ant-btn-primary:focus{
  background: #27374d;
  border: none;
}
.ant-btn-primary:hover{
  background: #4f698b;
}
.close_btn, .close_btn:focus {
  background-color: rgb(100 102 104);
  border: none;
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 6px;
  width: 100px;
  height: 37px;
}
.close_btn:hover{
  background-color: rgb(137 140 142);
  color: white;
}