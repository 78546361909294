* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../src/assets/font/inter/Inter.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/assets/font/dmsan/DMSans-VariableFont.ttf') format('truetype');
}


html,
body {
  font-family: "Inter" !important;
  /* background-color: #526D82; */
  /* background-color: #b2c6d6; */
  background: linear-gradient(135deg, #b2c6d6, #b1c5d766);
}

body {
  overflow-x: hidden;
}

h1 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  color: white;
}

.txt-center {
  text-align: center !important;
  color: white;
}

.text-center {
  text-align: center !important;
  color: white;
}

.txt-right {
  text-align: right !important;
  color: white;
}

.txt-left {
  text-align: left !important;
  color: white;
}

.blue {
  color: #050A30 !important;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.red {
  color: #DF4343 !important;
  text-decoration: underline;
  cursor: pointer;
}

.green {
  color: rgb(109, 192, 109) !important;
  text-decoration: underline;
  cursor: pointer;
}

.span-hr {
  background-color: #FFFFFF0F !important;
  padding: 1px;
}


/* header start */

.common-header {
  width: 100%;
  background: linear-gradient(45deg, #27374d, #27374da3);
  border-bottom: 1px solid #78777729;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.common-header .header-pathname {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.common-header .header-pathname h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.common-header .header-pathname b {
  color: #FFFFFF33;
  /* font-size: 20px; */
  font-weight: 500;
  /* margin-top: -5px; */
}

.common-header .header-pathname h3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #b5b7b8;
  margin: 0;
}

.header-notification {
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
}

.common-header .header-search .ant-input {
  height: auto;
  background-color: transparent;
}

.common-header .header-search .ant-input-affix-wrapper {
  height: 34px !important;
  width: 200px;
}

.common-header .header-search .notification-icon {
  cursor: pointer;
}

/* header end */

/* antd-layout start */

.ant-layout {
  background-color: transparent !important;
}

.ant-layout-sider {
  background-color: #27374D;
  height: 100vh;
  width: 100%;
  border-right: 1px solid hsla(0, 0%, 47%, 0.161);
  position: sticky !important;
  top: 0 !important;
  flex: 0 0 100px !important;
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  overflow-y: scroll;
  transition: all 0.5s ease;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
}

.skip_btn {
  background: linear-gradient(45deg, #426a97, #a1cfff);
  border: 1px solid #021d31;
  border-radius: 7px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.skip_btn:hover {
  background: linear-gradient(90deg, #a1cfff, #426a97);
  border: 1px solid #021d31;
  border-radius: 7px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #27374d;
  padding: 0px 6px;
  overflow-y: auto;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0199DC;
  color: white;
  border-radius: 5px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item {
  border-radius: 5px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  color: white;
  font-size: 14px;
}

/* antd-layout end */


/* common-table and tab start */


.common-tab a {
  color: #002140;
  font-size: 15px;
  /* padding-right: 20px;
  padding-left: 20px; */
  font-weight: 600 !important;
  position: relative;
  z-index: 99;
}

.common-tab .hrs {
  width: 100%;
  border-bottom: 1px solid #a9a8a8;
  margin-top: 3px;
}


.common-tab .tab-active {
  padding-bottom: 5px;
  border-bottom: 3px solid #728eb5;
}

.common-table {
  overflow-x: auto;
  margin-top: 10px;
  height: calc(100vh - 214px) !important;
  margin: 20px 0px 5px 0px;
}

.scan-inspection-list {
  overflow-x: auto;
  margin-top: 10px;
  height: calc(100vh - 270px) !important;
  margin: 20px 0px;
}

.scan-inspection-list .ant-form-item {
  margin: 0 0 0px;
}

.scan-inspection-list .ant-input-group .ant-input {
  width: 80px !important;
  height: 32px !important;
}

.scan-inspection-list .ant-input {
  width: 200px;
  height: 35px !important;
}

.number_input {
  width: 100%;
  border-radius: inherit !important;
}

.number_input[type=number] {
  width: 75px;
  text-align: center;
}
.okbtninput{
  width: 75px;
    text-align: center;
}
.scan-inspection-list .ant-input-group-wrapper {
  width: 180px;
}

.scan-inspection-list table thead th:nth-child(3),
.scan-inspection-list table thead th:nth-child(4),
.scan-inspection-list table thead th:nth-child(5),
.scan-inspection-list table thead th:nth-child(6) {
  padding-left: 0px;
}

.scan-inspection-list .ant-upload,
.ant-upload.ant-upload-drag,
.scan-inspection-list a {
  color: white;
}

.scan-inspection-list .ant-upload,
.ant-upload.ant-upload-drag {
  width: 15px;
  height: 30px;
  font-size: 18px;
  background-color: transparent;
  color: white;
  position: relative;
  top: 1px;
}

.scan-inspection-list .ant-upload.ant-upload-drag .anticon svg,
.scan-inspection-list .viewDelete-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.scan-inspection-list .viewDelete-file p {
  margin-bottom: 0px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* position: absolute; */
}

.scan-inspection-list .ant-upload.ant-upload-drag .anticon svg {
  color: white;
  position: relative;
}

.common-table table {
  width: 100%;
}

.common-table table thead {
  background-color: #27374d;
  /* border-bottom: 2px solid #78777729; */
  height: 45px !important;
  position: sticky;
  top: 0px;
  z-index: 99;
}

.common-table table thead tr {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  white-space: nowrap;
}

.common-table .remove-tr-hover:hover {
  background-color: transparent !important;
}

.common-table table thead tr .anticon {
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.common-table table thead tr th {
  width: 300px;
  color: #e0dfdf;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
}

.common-table table tbody tr td {
  width: 300px;
  font-weight: 600;
  border: none;
  border-bottom: 1px solid #58617421;
  color: #27374d;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding-left: 20px;
}

.common-table table tbody tr:hover {
  background-color: #7d92ae2b;
}

.common-table .dep-td {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  width: 620px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common-table .equ-dep-td {
  padding-left: 18px;
}

.common-table .table-department {
  display: flex;
  align-items: center;
  gap: 8px;
}

.common-table .table-department p {
  padding: 0px;
  margin: 0px;
}

.common-table .dot-green {
  height: 8px;
  width: 8px;
  background-color: #4AA785;
  border-radius: 50%;
}

.common-table .dot-purple {
  height: 8px;
  width: 8px;
  background-color: #8A8CD9;
  border-radius: 50%;
}


.common-table .dot-blue {
  height: 8px;
  width: 8px;
  background-color: #59A8D4;
  border-radius: 50%;
}

.common-table .dot-white {
  height: 8px;
  width: 8px;
  background-color: rgb(223, 212, 212);
  border-radius: 50%;
}

.common-table .depText-green {
  color: #4AA785;
}

.common-table .depText-purple {
  color: #8A8CD9;
}


.common-table .depText-blue {
  color: #59A8D4;
}

.common-table .depText-white {
  color: #FFFFFF;
}

.table-loadings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
}

.user-table-loadings {
  height: calc(100vh - 315px);
}


.table-loadings img {
  width: 260px;
}

.sub-table-loadings img {
  width: 10%;
  position: absolute;
  left: 49%;
  top: 50%;
}

.sub-table-data {
  height: 560px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.mini-loadings img {
  width: 10%;
  position: absolute;
  left: 47%;
  top: 50%;
}

.no-data-msg {
  position: absolute;
  padding: 100px 0px;
  width: 100% !important;
  text-align: center;
  color: #fff;
}

.no-data-msg h4 {
  color: #fff;
}

.no-datas {
  position: absolute;
  left: 45%;
  top: 50%;
}

/* common table end */


/* scrollbar start*/
/* 
body::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #27374d;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #27374d;
  border-radius: 10px;
}

/* scrollbar End*/

/* ant-input start */

.ant-input {
  background-color: white;
  font-size: 13px;
  font-weight: 500;
  color: black;
  height: 37px;
  border: none;
  padding-top: 5px;
  border-radius: 6px;
}

.ant-input:focus,
.ant-input-focused {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: none !important;
}

.ant-input-affix-wrapper {
  font-size: 13px;
  font-weight: 500;
  height: 37px;
  color: #27374D !important;
  border: none;
  border-radius: 6px;
}

.ant-input-affix-wrapper .ant-input {
  height: auto;
}

.ant-input::placeholder {
  color: gray !important;
  font-weight: 400;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:hover {
  border-color: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  position: absolute;
  right: -15px;
  top: 1px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: white !important;
}


/* .ant-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px rgb(73, 73, 73) inset !important;
}

.ant-input:-webkit-autofill::first-line {
  font-size: 13px;
  color: white;
} */

.ant-form-item-label>label {
  font-family: DM Sans !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #002140;
}

.ant-input-group-addon {
  background-color: transparent;
  border: none;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  width: 50px;
  padding: 0px;
  margin: 0px;

}

textarea {
  resize: none !important;
}

.ant-form-item-explain-error {
  font-size: 13.5px;
  font-weight: 500;
  color: #cb0b0b;
}

.ant-form-item {
  margin: 0 0 12px;
}


.ant-input[disabled] {
  background-color: #050A30;
  border: none;
  color: rgb(187, 185, 185);
}

.ant-input-status-error:hover {
  border-color: red;
}

/* ant-input end */



/* pagination start */

.ant-pagination-item a {
  color: white;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link {
  color: #0199DC;
}

.ant-pagination-item-active {
  background-color: #FFFFFF0D;
  border: none;
  border-radius: 5px;
  color: #0199DC;
}

/* pagination end */

/* ant-modal start */

.ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: justify !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white;
}

.ant-tooltip-placement-top {
  padding-bottom: 0px !important;
}

.ant-modal-header,
.ant-modal-footer,
.ant-modal-close-x {
  display: none;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  border-radius: 10px;
}

.ant-modal-body {
  background-color: #9db2c2;
  padding: 20px !important;
  border-radius: 10px;

}


.ant-modal-content {
  border-radius: 10px !important;
  background-color: #050A30;
}

/* ant-modal end */

/* antd-select start */


.ant-select .anticon {
  color: #0c2349;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white !important;
  border: none;
  /* padding: 4px 11px; */
  height: 37px;
  font-size: 13px;
  font-weight: 500 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  color: black !important;
  outline: none;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #FFFFFF1A !important;
  border: none !important;
}

.ant-select-selector:focus-within {
  border: 1px solid #FFFFFF1A !important;
  border: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus-within {
  border: 1px solid #FFFFFF1A !important;
  box-shadow: 0 0 2px #FFFFFF1A !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.ant-select-multiple .ant-select-selection-item-content {
  font-size: 13px;
  font-weight: 500;
  color: black;
}


.ant-select-item-option-content {
  color: white;
  font-size: 13px;
}


.ant-select-dropdown {
  background-color: #050A30;
  color: black;
  /* max-height: 200px;
  scroll-behavior: smooth;
  overflow-y: scroll; */
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #FFFFFF1A;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #fcfafa1a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  outline: none;
}

.ant-select-multiple .ant-select-selection-placeholder {
  color: #0c2349 !important;
  /* padding-top: 2px; */
  font-weight: 500;
}

.ant-select-selection-placeholder {
  color: gray !important;
}


.ant-select-selection-focused {
  border: none !important;
  background-color: #FFFFFF1A !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: transparent;
  border: none;
  padding-top: 1px;
}

/* antd-select end */

/* antd-checkbox start */
.ant-checkbox-inner {
  /* background-color: transparent; */
  border: 1px solid #FFFFFF33;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #27374d;
  border-color: #27374d;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #27374d;
}

.ant-checkbox-wrapper {
  color: white;
  font-size: 11px;
}

/* antd-checkbox end */

/* antd-upload start */

.ant-upload,
.ant-upload.ant-upload-drag {
  background-color: #9c9b9b1a;
  width: 100%;
  height: 90px;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ant-upload .upload-content {
  color: #FFFFFF33;
  display: block !important;
  text-align: center;
  width: 100%;
}


.ant-upload .upload-content h3 {
  font-size: 13px;
  font-weight: 500;
  color: #FFFFFF33;
  margin-top: 10px;
}

.ant-upload-list-picture .ant-upload-span,
.ant-upload-list-text .ant-upload-span:hover,
.ant-upload-list-picture .ant-upload-span,
.ant-upload-list-text .ant-upload-span:focus {
  background-color: #9db2c2 !important
}

.ant-upload-list-item-info {
  transition: none;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
  color: white;
  font-size: 13px;
  font-weight: 400;
}

.ant-upload-list {
  background-color: transparent;
}

.ant-upload-list .ant-tooltip-content {
  display: none;
}

.ant-upload-list-picture .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-text-icon .anticon {
  color: white;
}


/* antd-upload end */


/* config input start*/

.task-config .ant-input {
  background-color: #1C1C1C;
  font-size: 13px;
  color: white;
  border: none;
  border-radius: 6px;
  height: 32px;
}

.task-config .ant-input-affix-wrapper {
  background-color: #1C1C1C;
  font-size: 13px;
  color: white;
  border: none;
  border-radius: 6px;
}

.task-config .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #FFFFFF1A;
}

.task-config .ant-input-affix-wrapper>.ant-input:not(textarea) {
  margin-top: -4px;
  margin-left: 3px;
}

.task-config .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: white;
}

.task-config .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #1C1C1C;
}

.task-config .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #1C1C1C !important;
  border: none;
  padding: 5px 10px;
  font-size: 13px;
  height: 37px;
  border-radius: 5px !important;
  font-size: 12px !important;
  color: #BFBFBF;
  outline: none;
}

/* config input end */


/* antd-Data-Picker Start */

.ant-picker {
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 37px;
  font-size: 12px;
  border-radius: 5px;
}

.ant-picker-input>input::placeholder {
  color: gray;
  font-size: 13px;
}

.ant-picker-range-separator svg {
  color: #27374d;
}

.ant-picker-suffix svg {
  color: #27374d;
}

.ant-picker-clear svg {
  color: #27374d;
}

.ant-picker-focused,
.ant-picker:hover {
  border: none;
  box-shadow: none;
}

.ant-picker-input>input {
  font-size: 13px;
  font-weight: 500;
  color: #002140;
  font-size: 13px;
}

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  background-color: #1C1C1C;
  border: none;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #ffffff;
  border: none;
}

.ant-picker-content td,
.ant-picker-content th {
  border: none;
  text-align: center;
}

/* antd-Data-Picker end */


/* antd collaps start */

.ant-collapse {
  background-color: #0199DC;
  border: none;
  margin-bottom: 5px;
  border-radius: 5px;
}

.ant-collapse>.ant-collapse-item {
  border: none;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: white;
  font-weight: 500;

}

.ant-collapse-content {
  background-color: rgb(39, 39, 39);
  border: none;
}

/* antd colaps end */

/* antd-tab start */


.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  color: white;
  height: 35px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav,
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
  margin: 0px;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #27374d !important;
  border-radius: 4px;
}

/* antd-tab end */

/* Feature List popup modal start */

.feature-List-popup {
  margin-top: 0px !important;
  margin-right: 100px;

}

.feature-List-popup .spreadsheet-main {
  width: 100% !important;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header-new {
  background-color: rgb(77, 75, 75);
  text-align: center;
}

.feature-List-popup .spreadsheet-main h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
  padding-top: 5px;
}


/* .feature-List-popup .spreadsheet-main .ant-avatar {
  background-color: #FFFFFF0F;
  cursor: pointer;
} */

.feature-List-popup .spreadsheet-main .spreadsheet {
  margin-top: 10px;
  height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header .serial-header-column {
  width: 5%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main .ant-empty-image {
  margin-top: 15% !important;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header .inspector-header-column {
  width: 75%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header .inspectors-header-column {
  width: 35%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main .spreadsheet .spreadsheet-header .spec-range-header-column {
  width: 20%;
  text-align: center;
}

.feature-List-popup .ant-form-item {
  margin: 0 !important;
}




.feature-List-popup .spreadsheet-main #spreadsheet {
  margin-top: 10px;
  height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header .serial-header-column {
  width: 5%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header .inspector-header-column {
  width: 75%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header .inspectors-header-column {
  width: 35%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header .spec-range-header-column {
  width: 20%;
  text-align: center;
}


.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
}

.feature-List-popup .spreadsheet-main #spreadsheet .spreadsheet-header-new {
  background-color: rgb(75, 74, 74);
  text-align: center;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet {
  margin-top: 10px;
  height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header .serial-header-column {
  width: 5%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header .inspector-header-column {
  width: 75%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header .inspectors-header-column {
  width: 35%;
  text-align: center;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header .spec-range-header-column {
  width: 20%;
  text-align: center;
}


.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
}

.feature-List-popup .spreadsheet-main #csvSpreadSheet .spreadsheet-header-new {
  background-color: rgb(75, 74, 74);
  text-align: center;
}

.feature-action-button-delete.disabled {
  color: gray;
  cursor: not-allowed;
}

.feature-action-button-delete.deleted {
  color: red;
}

.serial-column {
  text-align: center;
  width: 5%;
}

.inspector-column {
  width: 75%;
}

.inspector-columns {
  width: 35%;
}

.spec-range-column {
  width: 20%;
}

.cell,
.cell:hover,
.cell:focus {
  padding: 5px;
  border: 1px solid black;
  background-color: #FFFFFF33;
  color: white;
  outline: none;
  white-space: nowrap !important;
}

.feature-List-popup .ant-modal-content .button-container {
  position: relative;
  bottom: -12px;
  width: 100%;
  text-align: center;
}

.button-container button {
  margin: 3px;
}

/* Feature List popup modal end */


/* Tracking Table start */

.tracking-table {
  width: 100% !important;
  height: 400px !important;
  overflow-x: auto;
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid #8b888829;
}

.tracking-table::-webkit-scrollbar {
  display: none;
}

.tracking-table:hover::-webkit-scrollbar {
  display: inline;
}

.tracking-table table {
  width: 100% !important;
}

.tracking-table thead th {
  text-align: left;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  background-color: rgb(58, 57, 57);
  border-left: 1px solid #8b888829;
  border-bottom: 1px solid #8b888829;
  border-right: 1px solid #8b888829;
  padding-bottom: 5px;
  color: white;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tracking-table tbody::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.tracking-table tbody::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 0px;
}

.tracking-table tbody::-webkit-scrollbar-track {
  background-color: #eee;
}

.tracking-table tbody tr td {
  font-size: 10px;
  padding-top: 20px;
  font-weight: 300;
  padding: 6px 10px;
  border: 1px solid #8b888829;
  padding-right: 20px;
  color: white;
  padding: 10px;
  text-align: left;
}


.table-pagination {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-pagination-btns {
  display: flex;
  align-items: center;
  gap: 15px;
}

.table-pagination h5 {
  font-size: 13px;
  color: #27374d;
  margin-bottom: 0px;
  font-weight: 600;
}

.table-pagination .ant-pagination-disabled .ant-pagination-item-link {
  background-color: #0199DC;
  border: none;
  border-radius: 5px;
}

.table-pagination .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  background-color: #0199DC;
  border: none;
  color: white;
  border-radius: 5px;
}

.table-pagination .ant-pagination-item {
  background-color: #FFFFFF0D;
  border: 1px solid transparent
}

.table-pagination .ant-pagination-item-active {
  background-color: #FFFFFF0D;
  color: #0199DC !important;
  border: 1px solid #0199DC !important;
}


.table-header-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.table-header-btns .copy-icon {
  width: 15px;
  height: 15px;
}


/* Tracking Table end*/

.equ-typ-input {
  height: auto !important;
  position: sticky;
  top: 0px !important;
  z-index: 999;
  background-color: #1C1C1C;
  border-radius: 5px;
  /* margin-right: -10px;
  margin-left: -10px; */
  margin-bottom: 5px;
}

.equ-typ-input .ant-input-affix-wrapper {
  height: auto;
}

.equ-typ-input .ant-input {
  background-color: transparent;
}

.ant-empty {
  color: white;
}

/* .ant-empty-image {
  margin-top: 160px;
} */

input[type=number],
input[type=password],
input[type=text],
textarea {
  width: 100%;
}

.ant-form-item {
  width: 100% !important;
}

.feature-action-button-delete {
  width: 40% !important;
  /* margin-right: 3px;
  margin-left: 3px; */
  background-color: #DF4343;
  color: #fff;
  border: none;
}

.feature-action-button-add {
  width: 40% !important;
  /* margin-right: 3px;
  margin-left: 3px; */
  background-color: #4AA785;
  color: #fff;
  border: none;
}

.feature-action {
  background-color: #FFFFFF33;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.ant-layout-sider {
  overflow-y: unset;
}

.loading {
  height: 25px;
  width: 25px;
}

.infinite-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: rgb(225, 215, 215);
}

.ref-master {
  width: 100%;
  padding-left: 10px;
}

.not-found {
  display: flex;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 100vh;
}

.ant-input[disabled] {
  background-color: #ffffff95;
  border: none;
  padding-top: 3px;
  color: black;
}

.ant-input-affix-wrapper-disabled,
.ant-picker.ant-picker-disabled {
  background-color: #ffffff95 !important;
  border: none;
  color: rgb(187, 185, 185) !important;
}

.preview-img {
  width: 16px !important;
}

.ant-picker.ant-picker-disabled {
  background-color: #FFFFFF1A !important;
  border: none;
}

.ant-picker-input>input[disabled] {
  color: rgb(187, 185, 185) !important;
}

.ant-picker .ant-picker-input .ant-picker-suffix span svg,
.ant-picker .ant-picker-input .ant-picker-clear span svg {
  color: #0c2349;
  font-size: 15px;
}

.loader-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: black;
}

.empty-center {
  position: absolute !important;
  left: 40% !important;
  top: 10%;
  text-align: center !important;
}

.report-empty-center {
  position: relative !important;
  left: 36vw !important;
  top: 53%;
}

.ant-picker-cell::before {
  top: 15px !important;
}

.webCam-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.webCam {
  max-height: 250px;
  margin-top: -15px;
  border-radius: 10px;
  border: 2px solid transparent;
}

.webCam-error {
  max-height: 250px;
  margin-top: -15px;
  border-radius: 10px;
  border: 2px solid red;
  box-shadow: rgba(255, 0, 0, 0.503) 0px 2px 8px 0px;
}


.webCam-main .profile-upload-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.webCam-main .profile-upload-btns .ant-btn {
  height: 40px;
  border-radius: 6px;
}

.ant-input-affix-wrapper .ant-input:-webkit-autofill {
  background: transparent;
  box-shadow: 0 0 0 0 transparent inset !important;
  border: none !important;
}

.ant-input-affix-wrapper .ant-input:-webkit-autofill {
  background: transparent;
  box-shadow: 0 0 0 0 transparent inset !important;
  border: none !important;
}

.add-submodule {
  padding: 3px 7px 3px 7px;
  ;
  border-radius: 6px;
  background: #364a66bf;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: left;
  border: 1px solid #8692a3;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ffffff;
}

.add-submodule:hover {
  background: #7f858ebf;
  border: 1px solid #7f8793;
}

.action_button_group {
  display: flex;
  gap: 12px;
  align-items: center;
}

.edit_view_group {
  display: flex;
  gap: 5px;
  font-size: 14px;
  background: #27374d;
  border-radius: 8px;
  padding: 6px;
  color: #ffffff;
  border: 1px solid #727ca3;
}

.delete_button {
  font-size: 14px;
  background: #b33e3e;
  border-radius: 8px;
  padding: 2px 6px;
  color: #ffffff;
  border: 1px solid #b87c7e;
}

.download_view_group {
  display: flex;
  gap: 5px;
  font-size: 14px;
  background: #336b6e;
  border-radius: 8px;
  padding: 6px;
  color: #ffffff;
  border: 1px solid #727ca3;
}

.upload_view_group {
  display: flex;
  gap: 5px;
  font-size: 14px;
  background: #334c6e;
  border-radius: 8px;
  padding: 6px 6px 6px 7px;
  ;
  color: #ffffff;
  border: 1px solid #727ca3;
}

.report_view_group {
  display: flex;
  gap: 5px;
  font-size: 14px;
  background: #303271;
  border-radius: 8px;
  padding: 6px;
  color: #ffffff;
  border: 1px solid #727ca3;
}

.ql-container.ql-snow {
  border: 1px solid #002140 !important;
  border-top: 0px !important;
  background: white;
}

.ql-toolbar.ql-snow {
  border: 1px solid #002140 !important;
}

.preview-pop-up {
  position: absolute;
  left: -20%;
  right: 0px;
  margin-top: -50px;
}

.preview-main {
  height: 510px;
  width: 800px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.preview-pop-up h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
  padding-top: 5px;
}

.preview-pop-up .ant-modal-content {
  width: 850px;
}

.preview-pop-up .ant-avatar {
  background-color: #FFFFFF0F;
  cursor: pointer;
}

.preview-pop-up .pg-viewer-wrapper {
  overflow-y: auto !important;
}

.ant-select-selection-overflow {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.ant-select-selection-search {
  /* width: 100%; */
}

.ant-select-selection-overflow::-webkit-scrollbar {
  display: none;
}

.ant-modal-body h2 {
  color: #002140 !important;
  font-size: 16px !important;
}

.reset_popup .ant-modal-content .ant-modal-body {
  background-color: white;
  padding: 20px;
}

.reset_popup .ant-modal-content .ant-modal-body p {
  text-align: center;
  font-size: 1.17em !important;
  font-weight: 600 !important;
  color: black;
  margin-bottom: 25px;
}

.reset_popup .ant-modal-content .ant-modal-body .no_btn {
  background-color: grey;
  border: 1px solid grey;
  border-radius: 4px;
  color: white;
  margin-bottom: 5px !important;
}

.reset_popup .ant-modal-content .ant-modal-body .yes_btn {
  background-color: #002140;
  border: 1px solid #002140;
  border-radius: 4px;
  color: white;
  margin-bottom: 0 !important;
}

.common-table table tbody tr td p {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.ant-input:hover {
  background: #deedff;
}

.inspector-column:hover {
  background: #9dabbc !important;
}

.inspector-column-spec:hover {
  background: #9dabbc !important;
}

.serial-column:hover {
  background: #9dabbc !important;
}

.ant-input-affix-wrapper:hover {
  background: #deedff;
}

.button_form_item {
  margin: 0px 0 4px;
}

.loading_img {
  width: 50px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #b8b8b8;
}

.animated-card {
  opacity: 0;
  animation: slideInFromTop 0.5s ease forwards;
}

.pop-up .ant-avatar .anticon svg {
  /* margin: 0px 0px 0px 1px;
  font-size: 17px; */
}

.pop-up .ant-avatar {
  background-color: #53606e5e;
  cursor: pointer;
  border: 1px solid #cae4ff59;
  font-size: 17px;
}

.pop-up .ant-avatar:hover {
  background-color: #53606ead;
  border: 1px solid #cae4ff59;
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
    /* Slide in from the top */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* End at the original position */
  }
}

.increment_btn {
  width: 40px !important;
  height: 35px;
  border-radius: inherit;
  /* margin-left: 6px; */
}
.version-sidebar{
  position: fixed;
  bottom: 55px;
  z-index: 99;
  color: #bbbcbe;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .7px;
  left: 10px;
}
.version_footer .version{
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  /* right: 0; */
  left: 20px;
}
.footer {
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  /* padding: 0 40px; */
}
.footer p {
  color: #555555;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .7px;
  padding-right: 25px;
}